import routerOptions0 from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.11.2_oxvkg6tj37rjnnktuy7dvzi4hm/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/runner/work/ott-web-portal2/ott-web-portal2/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}