import validate from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.11.2_oxvkg6tj37rjnnktuy7dvzi4hm/node_modules/nuxt/dist/pages/runtime/validate.js";
import add_45trailing_45slash_45before_45qp_45global from "/home/runner/work/ott-web-portal2/ott-web-portal2/middleware/add-trailing-slash-before-qp.global.ts";
import club_45global from "/home/runner/work/ott-web-portal2/ott-web-portal2/middleware/club.global.ts";
import skip_45auth_45page_45global from "/home/runner/work/ott-web-portal2/ott-web-portal2/middleware/skip-auth-page.global.ts";
import manifest_45route_45rule from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.11.2_oxvkg6tj37rjnnktuy7dvzi4hm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  add_45trailing_45slash_45before_45qp_45global,
  club_45global,
  skip_45auth_45page_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/middleware/auth.ts"),
  "disable-team-page": () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/middleware/disable-team-page.ts")
}